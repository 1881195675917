import { useContext, useReducer, createContext, useCallback } from "react";

import { useRequest } from "../services/request";


const Context = createContext();

export function useHnndes() {
  return useContext(Context);
}

const initState = {
  list: [],//for employee List,
  
  selected: null,//for project Info
plist:[],//For Project List
alist:[],//For articles List

  status: "idle",
  count: 0,//num of pages
  page: 0,//num of current page //send as parameter
  perPage: 10,//size of data in per page //send as parameter
  totalSize: 0,//length od data // get it from resdata.data.count
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
      case "set_plist":
      return { ...state, plist: [...action.payload] };
      case "set_alist":
        return { ...state, alist: [...action.payload] };
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
 
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
      case "set_count":
        return { ...state, count: action.payload };
        case "set_totalSize":
      return { ...state, totalSize: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function HnndesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

 

  const fetchEmployees = useCallback(
    (id,data) => {
 
      return new Promise(async (resolve, reject) => {
      
        dispatch({ type: "status", payload: `fetching` });
        try {
      

          let resData=null;
          let formData = new FormData();
         
          formData.append("ids",id)
           formData.append("departments",data);
          
       
         
           resData = await req(
            `api/v1/WebSite/EmployeeList`,
            null,
            { method: "GET" , headers:formData},
            false
          );
       
       
          dispatch({ type: "set_list", payload: resData.data });
       
          dispatch({ type: "status", payload: `idle` });
          console.log(resData.data,"data")
          // get it from response from swagger "resData.data"
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchProjects = useCallback(
    (id,page,perpage) => {
      let total_pages=state.totalSize/state.perPage
      page=Number(state.page+1)
      perpage=state.perPage
      return new Promise(async (resolve, reject) => {
      
        dispatch({ type: "status", payload: `fetching` });
        try {
        //id is company ID

          let resData=null;
          let formData = new FormData();
         
           formData.append("pageNumber",page);
           formData.append("pageSize",perpage);
           formData.append("companyIds",id);
           resData = await req(
           
            `api/v1/WebSite/ProjectList`,
            null,
            { method: "GET" , headers:formData},
            false
          );
       
       
          dispatch({ type: "set_plist", payload: resData.data.projects||[]});
          
          dispatch({ type: "set_totalSize", payload: resData.data.totalSize||0});
          dispatch({ type: "set_count", payload: resData.data.totalPages || 1 });//count of pages
         // dispatch({ type: "set_totalSize", payload: resData.data.count || 0 });
          dispatch({ type: "status", payload: `idle` });
          console.log(resData.data,"data")
          // get it from response from swagger "resData.data"
          resolve(resData.data.projects);
          console.log("totalSize",resData.data.totalSize);
          console.log("totalPages",resData.data.totalPages);

        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req,state.page,state.perPage]
  );

  const fetchProjectInfo = useCallback(
    (id,data) => {
 //id is project id 
 // data is company id
      return new Promise(async (resolve, reject) => {
      
        dispatch({ type: "status", payload: `fetching` });
        try {
        

          let resData=null;
          let formData = new FormData();
     
           formData.append("companyId",data);
       
         
           resData = await req(
            `api/v1/WebSite/ProjectInfo/${id}`,
            null,
            { method: "GET" , headers:formData},
            false
          );
       
       
          dispatch({ type: "set_selected", payload: resData.data });
       
          dispatch({ type: "status", payload: `idle` });
          console.log(resData.data,"data")
          // get it from response from swagger "resData.data"
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchArticles= useCallback(
    (id,page,perpage) => {
      let total_pages=state.count/state.perPage
      page=Number(state.page+1)
      perpage=state.perPage
      return new Promise(async (resolve, reject) => {
      
        dispatch({ type: "status", payload: `fetching` });
        try {
        //id is company ID

          let resData=null;
          let formData = new FormData();
          // { method: "GET" , headers:{data}},
           formData.append("pageNumber","");
           formData.append("pageSize","");
           formData.append("companyIds",id);
      
           resData = await req(
           
            `api/v1/WebSite/ArticleList`,
            null,
            { method: "GET" , headers:formData},
            false
          );
       
       
          dispatch({ type: "set_alist", payload: resData.data.articles||[]});
          console.log(resData.data.articles,"data")
          dispatch({ type: "set_count", payload: total_pages || 1 });//count of pages
          dispatch({ type: "set_totalSize", payload: resData.data.count || 0 });
          dispatch({ type: "status", payload: `idle` });
          console.log(resData.data.articles,"data")
          // get it from response from swagger "resData.data"
          resolve(resData.data.articles);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req,state.page,state.perPage]
  );
  const fetchArticleInfo = useCallback(
    (id,data) => {
 //id is project id 
 // data is company id
      return new Promise(async (resolve, reject) => {
      
        dispatch({ type: "status", payload: `fetching` });
        try {
        

          let resData=null;
          let formData = new FormData();
     
           formData.append("companyId",data);
       
         
           resData = await req(
            `api/v1/WebSite/ArticleInfo/${id}`,
            null,
            { method: "GET" , headers:formData},
            false
          );
       
       
          dispatch({ type: "set_selected", payload: resData.data });
       
          dispatch({ type: "status", payload: `idle` });
          console.log(resData.data,"data")
          // get it from response from swagger "resData.data"
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
     
        fetchEmployees,fetchProjectInfo,fetchProjects,fetchArticles,fetchArticleInfo
      }}
    >
      {children}
    </Context.Provider>
  );
}
