import React, { useEffect } from "react";
import "./styles/rankslider.scss";
import Slider from "react-slick";
import ola from "../../../assets/olaRank.svg"

import bayan from "../../../assets/bayanR.svg"
import basem from "../../../assets/basemR.svg"
import alaa from "../../../assets/alaaR.svg"
import mohamad from "../../../assets/mouhamadR.svg"
import batool from "../../../assets/batoulR.svg"
import alice  from "../../../assets/aliceR.svg"
import nour from "../../../assets/nour.svg"
import martin from "../../../assets/martin.svg"
import carol from "../../../assets/carol.svg"
import rama from "../../../assets/rama.svg"
import elie from "../../../assets/elie.svg"
import esraa1 from "../../../assets/esraa1.svg"
import thamen from "../../../assets/thamen.svg"
import anas from "../../../assets/anas.svg"
import nohaa from "../../../assets/nohaa.svg"
import star from "../../../assets/hero/star.svg";
import ghewa from "../../../assets/ghewa.svg";
import habib from "../../../assets/habib.svg";
import rouaa from "../../../assets/rouaa.svg";
import abd from "../../../assets/abd.svg";
import { useHnndes } from "view/provider";
function RankSlider() {
  const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
 
   const {
      state: { list: Emps },
  
      fetchEmployees: fetchEmps,
    } = useHnndes();
  
    useEffect(() => {
     
    fetchEmps("1;2", "");
    
     console.log(Emps,"emps")
    }, [fetchEmps]);
  return (
 
    <div className="rankslider">
      <Slider className="slider" {...settings}>
        {Emps.map((slide) => {
          return (
            <>
           
              <div className="slide">
                {/* <div className="d-flex"> */}
                  <div className="imgrank">
                    <img
                      src={slide.imageUrl}
                      alt={slide.fullName.split(' ').slice(0, -1).join(' ')}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="text">
                    <p>
                      <span className="name">{slide.fullName.split(' ').slice(0, -1).join(' ')}</span>
                   
                    </p>
                    <p className="position">
                      <span>{slide.jobTitle}</span>
                      <span className="sscompany">{slide.about}</span>
                    </p>
                  </div>
                {/* </div> */}
           
              </div>
            </>
          );
        })}
      </Slider>
    
    </div>
  );
}

export default RankSlider;
