import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import reportWebVitals from "./reportWebVitals";
// import Providers from "";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeRoutes from "./view/home/routes";
import AboutRoutes from "./view/About/routes";
import ContactRoutes from "./view/Contact/routes";
import ProjectsRoutes from "./view/projects/routes";
import NewsRoutes from "./view/News/routes";
import SoonRoutes from "./view/Soon/routes";
//import Providers from "./services/providers"

//import Providers from '../src/services/providers'
import Providers from "services/providers";
import NewsDetails from "view/News/NewsDetails";
import ProjectInfo from "view/projects/ProjectInfo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <Providers>
    <BrowserRouter>
      <Routes>
      <Route path="news/newsDetails/:id" element={<NewsDetails />} />
      <Route path="projects/projectDetails/:id" element={<ProjectInfo />} />
     
        {SoonRoutes}
        {HomeRoutes}
        {AboutRoutes}
        {ContactRoutes}
        {ProjectsRoutes}
         {NewsRoutes} 
      </Routes>
    </BrowserRouter>
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
