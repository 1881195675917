import React from 'react'
import afterImg from "../../assets/afterRightContent.svg"
import styled from 'styled-components'

import blackCircle from "../../assets/blackC.svg"
const TagItemRow=styled.div`
display: flex;
margin-top: 65px;
margin-bottom: 65px;
.left-content{
    width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
gap: 20px;
color: #000;
border-right:2px dashed #121212;
//padding-right: 3%;
position: relative;
.imgB1{
    position: absolute;
    top: -11px;
    right: -10px;
}
.imgB2{
    position: absolute;
    bottom: -11px;
    right: -10px;
}
.p1{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;

  
}
.p2{
    width: 80%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
}

}
.right-content{
    width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
gap: 20px;
color: #000;
padding-left: 10%;
.p1{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
 
  
}
.p2{
    width: 80%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
}

}

`;
function TagItem({leftContent,rightContent}) {
  return (
   <TagItemRow style={{marginLeft:"65px"}}>
    <div className='left-content'>
    <p className='p1'>Business context</p>
    <p className='p2'>{leftContent}</p>
    <img src={blackCircle} className='imgB1' />
    <img src={blackCircle} className='imgB2' />
    </div>
    <div className='right-content'>
    <p className='p1'>Value delivered</p>
    <p className='p2'>{rightContent}</p>
    </div>
   </TagItemRow>
  )
}

export default TagItem
