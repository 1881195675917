import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import NavHeader from 'view/home/NavHeader'
import "./styles/customprojects.scss"
import { Col, Container, Row } from 'react-bootstrap';
import MainTitle from 'view/MainTitle';
import TagItem from './TagItem';
import { Flex } from 'ui';
import img1 from "../../assets/liban call l.svg"
import img2 from "../../assets/libancall3.svg"
import img3 from "../../assets/LibanCall5f.jpg"
import img4 from "../../assets/lightLogo.svg";
import { Link } from 'react-router-dom';
import "../home/NavHeader.scss"
import FooterLayout from 'layout/Footer/FooterLayout';
import { useHnndes } from 'view/provider';
import HTMLReactParser from 'html-react-parser';
const StyledProject = styled.div`
  .mainProject {
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    overflow-x: hidden;
    overflow-y: hidden;

    .tag-div{
cursor: pointer;
        max-width: fit-content;

padding: 8px 12px 8px 12px;

border-radius: 5px;
background:linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);

font-family: Poppins;
font-size: 20px;
font-weight: 600;
//line-height: 36px;

color:black;
    }
  }
  .img-p-info{
    width: 90%;
    height: 700px;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
  height:450px;
    }
    @media screen and (max-width: 474px) {
  height:350px;
    }
  }
  .img-p-info1{
    width: 103px;
height: 80px;

border-radius: 10px;

opacity: 0px;

    box-shadow: 0px 0px 15px 3px #00000017;
cursor: pointer;
  }
  .p-info-fo{
    width:50%;
    font-family: Poppins;
font-size: 28px;
font-weight: 500;
//line-height: 59.08px;
text-align: center;
color: black;
@media screen and (max-width: 767px) {
  font-size: 20px;
    }
    @media screen and (max-width: 474px) {
      font-size: 18px;
    }

  }
  .contactBtnInfoP{
    margin-bottom:30px;
    width: 156px;
    height: 40px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    color: white;
  }
  `;
const InfoContainer=styled.div`
//margin-top:10px;

/* width:100%;
height:650px;
background-image:url(${(p)=>p=p.img}) ;
background-repeat: no-repeat;
 // background-attachment: fixed; 
  background-size: 100% 100%;
  .main-div-img{ */
width:100%;
height:700px;
position:relative;
@media screen and (max-width: 767px) {
  height:450px;
    }
    @media screen and (max-width: 474px) {
  height:350px;
    }

`;
function ProjectInfo() {
    const { state } = useLocation();
    const Project =state.selected_project;
   // const idp=useParams();
     const {
        state: { selected},
    
        fetchProjectInfo: fetchProInfo,
      } = useHnndes();
    
      useEffect(() => {
        fetchProInfo(Project.id,Project.companyId);
        console.log("projectInfo",selected)
       
      }, [fetchProInfo]);
   // img=Project.imageUrl;
  const  tags= [
    {
      tag: "UIUX Design",
      businessContext: "The main challenge Lifeworks Advisors faced was using separate systems for aggregating customers’ financial data, managing financial plans, opening investment accounts, and monitoring investment activity.",
      valueDelivered: "A single system that handles all wealth management operations and allows for business scaling"+
"On-time delivery of a solution that met all the client’s needs and requirements. ",
      id: 1,
      projectId: 1
    },
    {
        tag: "Front-End Development",
        businessContext: "2The main challenge Lifeworks Advisors faced was using separate systems for aggregating customers’ financial data, managing financial plans, opening investment accounts, and monitoring investment activity.",
        valueDelivered: "2A single system that handles all wealth management operations and allows for business scaling"+
  "On-time delivery of a solution that met all the client’s needs and requirements. ",
        id: 2,
        projectId: 1
      },
      {
        tag: "Back-End Development",
        businessContext: "3The main challenge Lifeworks Advisors faced was using separate systems for aggregating customers’ financial data, managing financial plans, opening investment accounts, and monitoring investment activity.",
        valueDelivered: "3A single system that handles all wealth management operations and allows for business scaling"+
  "On-time delivery of a solution that met all the client’s needs and requirements. ",
        id: 3,
        projectId: 1
      },
      {
        tag: "Testing",
        businessContext: "4The main challenge Lifeworks Advisors faced was using separate systems for aggregating customers’ financial data, managing financial plans, opening investment accounts, and monitoring investment activity.",
        valueDelivered: "4A single system that handles all wealth management operations and allows for business scaling"+
  "On-time delivery of a solution that met all the client’s needs and requirements. ",
        id: 4,
        projectId: 1
      }

  ];
  const  files= [
    {
      fileName: "name1",
      id: 1,
      projectId: 1,
      fileUrl: img3
    },
    {
        fileName: "name2",
        id: 2,
        projectId: 1,
        fileUrl: img1
      },
      {
        fileName: "name1",
        id: 3,
        projectId: 1,
        fileUrl: img2
      },
      {
        fileName: "name1",
        id: 4,
        projectId: 1,
        fileUrl: img4
      },
  ];
const[id,setId]=useState(0);
const[imgId,setImgId]=useState(0)
  return (
    <div >
        
         <NavHeader />
        
       {/* <InfoContainer img={selected?.imageUrl}>


       </InfoContainer> */}
       
       <InfoContainer  >
<img style={{position:"absolute",objectFit:"fill",width:"100%",height:"100%",top:0,left:0}} src={selected?.imageUrl} loading='lazy' />
       </InfoContainer>


        <StyledProject>
        <div className="mainProject">
      <MainTitle
        fTitle="Services "
        sTitle="Performed"
        subtitle="FULL-CYCLE SERVICES FOR YOUR NEEDS"
       // details=" We provide end-to-end app and web development services at affordable rates. Also we help global brands to develop digital products from scratch."
      />
      
        <Row style={{marginLeft:"65px",gap:"35px",marginBottom:"30px"}}>
            {
            selected?.tags?.map((tag,index)=>
            <Col className='tag-div' onClick={()=>setId(index)}>{tag.tag}</Col>

            )}
        </Row>
         {

        id===0 && selected?.tags?.length >=1 && ( 
 <TagItem
leftContent={ HTMLReactParser(
  selected?.tags[0]["businessContext"]+"")}
rightContent={HTMLReactParser(selected?.tags[0]["valueDelivered"]+"")}
 /> 
        )}
         {
        id===1 &&  selected?.tags?.length >=2 &&(
            <TagItem
            leftContent={ HTMLReactParser(selected?.tags[1]["businessContext"]+"")}
            rightContent={ HTMLReactParser(selected?.tags[1]["valueDelivered"]+"")}
             />
                    )
        }
         {
        id===2 && selected?.tags?.length >=3 &&(
            <TagItem
            leftContent={ HTMLReactParser(selected?.tags[2]["businessContext"]+"")}
            rightContent={ HTMLReactParser(selected?.tags[2]["valueDelivered"]+"")}
             />
                    )
        }
         {
        id===3 && selected?.tags?.length >=4 &&(
            <TagItem
            leftContent={ HTMLReactParser(selected?.tags[2]["businessContext"]+"")}
            rightContent={ HTMLReactParser(selected?.tags[2]["valueDelivered"]+"")}
              />
               )
        }
         {
        id===4 && selected?.tags?.length ===5 && (
            <TagItem
            leftContent={ HTMLReactParser(selected?.tags[3]["businessContext"]+"")}
            rightContent={ HTMLReactParser(selected?.tags[3]["valueDelivered"]+"")}
             />
                  )
        } 
        <Flex style={{justifyContent:"center",alignItems:"center"}}>
 <MainTitle
        fTitle="Project"
        sTitle="Visual"
        subtitle="Attractive user interfaces"
       // details=" We provide end-to-end app and web development services at affordable rates. Also we help global brands to develop digital products from scratch."
      />
</Flex>
<Flex style={{flexDirection:"column"}}>
    <img className='img-p-info' src={selected?.files[imgId]?.fileUrl} alt={selected?.files[0]?.fileName} />
    <Flex style={{gap:"30px",justifyContent:"flex-start",alignItems:"flex-start",width:"100%",marginTop:"20px"}}>
{selected?.files?.map((file,index)=><img className='img-p-info1' src={file?.fileUrl} onClick={()=>setImgId(index)} />)}
    </Flex>
<Flex style={{width:"100%",marginTop:"50px",flexDirection:"column"}}>
    <p className='p-info-fo'>Ready to help you transform your business and achieve greater success
   
    </p>
    
    <Link className="contactBtnInfoP" to="/contact">Contact Us</Link>
</Flex>
</Flex>
        </div>

        </StyledProject>
        <FooterLayout />
    </div>
  )
}

export default ProjectInfo
