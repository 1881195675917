import React, { useState } from "react";
import "./styles/customprojects.scss";
import { Col, Row } from "react-bootstrap";
import placeholder from "../../assets/projects/placeholder.svg";
import { useEffect } from "react";
// import img1 from "../../assets/projectsi/Phone_Long_Scroll_Mockup_v01 copy.jpg";

// import img2 from "../../assets/projectsi/tobsir poster copy (1).jpg";
// import img3 from "../../assets/projectsi/smart wave poster copy.jpg";
// import img4 from "../../assets/projectsi/med hub poster CO (1).jpg";
import img1 from "../../assets/caseStudies/LibanCall.jpg";

import img2 from "../../assets/caseStudies/tobsir poster copy.jpg";
import img3 from "../../assets/caseStudies/smart wave poster copy.jpg";
import img4 from "../../assets/caseStudies/med hub poster CO.jpg";
import GreenArrow from "../../assets/GreenArrow.svg"
import WhiteArrow from "../../assets/WhiteArrow.svg"

import { useHnndes } from "view/provider";
import { Flex, Icon, Pagination} from "../../ui";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";


const InfoContainer=styled.div`
//margin-top:10px;

/* width:100%;
height:650px;
background-image:url(${(p)=>p=p.img}) ;
background-repeat: no-repeat;
 // background-attachment: fixed; 
  background-size: 100% 100%;
  .main-div-img{ */
width:100%;
height:700px;
position:relative;
@media screen and (max-width: 767px) {
  height:450px;
    }
    @media screen and (max-width: 474px) {
  height:350px;
    }

`;

  function CustomProjects(){
  const {
    state: { plist:projects,totalSize,perPage,page}, dispatch,

    fetchProjects: fetchPros,
  } = useHnndes();

  useEffect(() => {
    fetchPros("1;2",page,perPage);
    console.log("projects",projects)
   
  }, [fetchPros]);
  const myref=useRef(null);
  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
const[hover,setHover]=useState(false)

const setHoverID=(i)=>{
console.log(i,"i");
console.log(myref.current.id,"id");
setId(myref.current.id);
if(i===myref.current.id)
{
setHover(!hover);
}
}
const[id,setId]=useState("1")
const handleClick=(e)=>{
  //console.log("id",myref.current?.id);    
  setId(e.target.id);
  console.log("d",id);
 

}
const [loaded, setLoaded] = useState(false);

const handleLoad = () => {
  setLoaded(true);
};
let navigate=useNavigate();
  return (
    <div className="CustomProjects">
      {/* <Row  lg="2" md="1" sm="1" xs="1"  >
       
         { projects?.map(({},i)=>(
            <Col  className="" >
              <div className="card">
                <div>
                  <img
                  style={{borderRadius:"10px"}}
                    src={projects[i]["imageUrl"]}
                    alt={projects[i]["projectName"]}
                   width="100%"
                   height="100%"/>
                </div>
                <div className="titlepro">
                  <p>{projects[i]["projectName"]}</p>
                  {projects[i]["tagList"]?.map((tool) => (
                    <span >{tool}</span>
                  ))}
                </div>
              </div>
            </Col>
          ))}
            <Flex style={{justifyContent:"flex-end",height:"100px",
    width:"100%"}}>
  
      <Pagination
              sx={{ mt: 20 }}
              data-testid="product-pagination"
              count={6}
              page={page}
              onPa  geChange={handleChangePage}
              rowsPerPage={perPage}
              
            /> 
   </Flex>
      </Row> */}
       <Row  lg="3" md="2" sm="1" xs="1"  >
       
       { projects?.map(({},i)=>(
      
//           < onMouseOver={handleClick}    itemRef={myref} id={i+1+""} className="project-Col" style={{
//             position:"relative",height:"500px",padding:"20px",border:"1px solid #B8B7B7",marginBottom:"20px",
// backgroundImage:id!==i+1+""?"url(" +

// projects[i]["imageUrl"]
// +")":"",backgroundSize:"cover",backgroundRepeat:"no-repeat"
// ,cursor:"pointer",
// filter: loaded ? "none" : "blur(20px)",
// transition: "filter 0.5s",
//           }}
        
//           >
           <Col className="project-Col"  >
           <img src={projects[i]["imageUrl"]} id={i+1+""} onMouseOver={handleClick} style={{display:id!==i+1+""?"block":"none",position:"absolute",objectFit:"fill",width:"100%",height:"100%",top:0,left:0}}  loading='lazy' />
              <div className="titlepro">
                <p style={{color:id!==i+1+""?"white":"black"}}>{projects[i]["projectName"]}</p>
               { id!==i+1+""&& <img  className="d-inline-block align-top plogo10" src={projects[i]["logoUrl"]} alt=" " /> }
               <p  style={{fontSize:"18px",fontWeight:"400",lineHeight: "normal",color:"black",display:id!==i+1+""?"none":"block" }}>{projects[i]["description"]}</p>
              </div>
           <div className="link-col"
          //  onClick={  navigate("projectInfo",{ state: {selected_project: projects[i] } })}
          onClick={() =>navigate(`projectDetails/${projects[i]["id"]}`,{ state: {selected_project: projects[i] } } )} 
           >
            <Link 
          // to={ navigate("projectInfo",{ state: {selected_project: projects[i] } })}
            style={{fontSize:"20px",fontWeight:"400",color:id!==i+1+""?"white":"black"}} >
           SEE ALL CASE SYUDY</Link><img src={id!==i+1+""?WhiteArrow:GreenArrow} alt=""/></div>
           {/* <button  onClick={  navigate("projectInfo",{ state: {selected_project: projects[i] } })}>Presssss</button> */}
           </Col>
          
            // <Col  className="project-Col" style={{height:"500px",padding:"20px",border:"1px solid #B8B7B7",marginBottom:"20px",
             
              
            //             }}
                     
            //             >
            //               <div className="cardd" id={i+""} ref={myref}  onMouseOver={()=>setHoverID(i)} onMouseLeave={()=>setHover(false)}>
                          
            //                 <div className={hover?"titlepro1":"titlepro"}>
            //                   <p>{projects[i]["projectName"]}</p>
            //                   <img  className="d-inline-block align-top plogo10" src={projects[i]["logoUrl"]} alt=" " /> 
                          
            //                 </div>
            //               </div>
            //             </Col>

          
        ))}
          <Flex style={{justifyContent:"flex-end",height:"100px",
  width:"100%"}}>

    <Pagination
            sx={{ mt: 20 }}
            data-testid="product-pagination"
            count={6}
            page={page}
            onPa  geChange={handleChangePage}
            rowsPerPage={perPage}
            
          /> 
 </Flex>
    </Row>
    
    </div>
  );
}

export default CustomProjects;
